import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "students" }
const _hoisted_2 = { class: "students__block" }
const _hoisted_3 = { class: "students__students-top-block-wrap" }
const _hoisted_4 = { class: "students__search-wrap" }
const _hoisted_5 = { class: "students__filters-wrap" }
const _hoisted_6 = { class: "students__data-range-wrap" }
const _hoisted_7 = { class: "students__data-range-wrap" }
const _hoisted_8 = {
  for: "subscription",
  class: "custom-checkbox"
}
const _hoisted_9 = {
  for: "subscription",
  class: "custom-checkbox"
}
const _hoisted_10 = { class: "students__search-wrap-mobile-container" }
const _hoisted_11 = { class: "students__search-wrap mobile-search" }
const _hoisted_12 = { class: "main-table__mobile-wrap" }
const _hoisted_13 = { class: "main-table__head" }
const _hoisted_14 = { class: "main-table__head-id" }
const _hoisted_15 = { class: "main-table__sort-block" }
const _hoisted_16 = { class: "main-table__head-big-tr" }
const _hoisted_17 = { class: "main-table__sort-block" }
const _hoisted_18 = { class: "main-table__head-big-tr" }
const _hoisted_19 = { class: "main-table__sort-block" }
const _hoisted_20 = { class: "main-table__head-big-tr" }
const _hoisted_21 = { class: "main-table__sort-block" }
const _hoisted_22 = { class: "main-table__body-id" }
const _hoisted_23 = { class: "main-table__body-little-tr" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "main-table__body-big-tr" }
const _hoisted_26 = { class: "main-table__body-big-tr" }
const _hoisted_27 = { class: "main-table__body-big-tr" }
const _hoisted_28 = { class: "main-table__body-big-tr" }
const _hoisted_29 = { class: "main-table__body-big-tr" }
const _hoisted_30 = { class: "students__popup-buttons" }
const _hoisted_31 = { class: "students__choose-instructor" }
const _hoisted_32 = { class: "students__search-wrap students__search-choose-instructor" }
const _hoisted_33 = { class: "students__choose-instructor-wrap" }
const _hoisted_34 = ["for"]
const _hoisted_35 = ["id", "name", "onClick", "checked"]
const _hoisted_36 = ["id", "name", "onClick", "checked"]
const _hoisted_37 = { class: "error" }
const _hoisted_38 = { class: "students__choose-instructor" }
const _hoisted_39 = { class: "students__search-wrap students__search-choose-instructor" }
const _hoisted_40 = { class: "students__choose-instructor-wrap" }
const _hoisted_41 = ["for"]
const _hoisted_42 = ["id", "name", "onClick", "checked"]
const _hoisted_43 = { class: "students__choose-instructor" }
const _hoisted_44 = { class: "students__copy-code-wrap" }
const _hoisted_45 = { class: "students__copy-code" }
const _hoisted_46 = {
  key: 4,
  class: "students__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Search = _resolveComponent("Search")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_AddInstructor = _resolveComponent("AddInstructor")!
  const _component_View = _resolveComponent("View")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ]),
      _createElementVNode("div", {
        class: "students__new-student-button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openPopupChoosenInstructorNewStudent && _ctx.openPopupChoosenInstructorNewStudent(...args)))
      }, " New student ")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "students__data-range",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
        }, [
          _cache[24] || (_cache[24] = _createTextVNode(" Date range ")),
          _createVNode(_component_SelectArrow, {
            class: _normalizeClass({ isOpenSelectArrow: _ctx.isCalendarVisible })
          }, null, 8, ["class"])
        ]),
        _withDirectives(_createVNode(_component_Calendar, {
          class: "students__calendar",
          "select-date-fill": this.selectDateFill,
          "selected-new-date": this.selectedNewDate
        }, null, 8, ["select-date-fill", "selected-new-date"]), [
          [_vShow, _ctx.isCalendarVisible]
        ])
      ])), [
        [_directive_click_outside, _ctx.closeCalendar]
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "students__data-range",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendarSubsqribe && _ctx.toggleCalendarSubsqribe(...args)))
        }, [
          _cache[25] || (_cache[25] = _createTextVNode(" Date range ")),
          _createVNode(_component_SelectArrow, {
            class: _normalizeClass({ isOpenSelectArrowSubsqribe: _ctx.isCalendarSubsqribeVisible })
          }, null, 8, ["class"])
        ]),
        _withDirectives(_createVNode(_component_Calendar, {
          class: "students__calendar",
          "select-date-fill": this.selectDateFillSubsqribe,
          "selected-new-date": this.selectedNewDateSubsqribe
        }, null, 8, ["select-date-fill", "selected-new-date"]), [
          [_vShow, _ctx.isCalendarSubsqribeVisible]
        ])
      ])), [
        [_directive_click_outside, _ctx.closeCalendarSubsqribe]
      ]),
      _createElementVNode("label", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          id: "subscription",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSubscription) = $event)),
          type: "checkbox",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.checkedShowSubscription && _ctx.checkedShowSubscription(...args)))
        }, null, 512), [
          [_vModelCheckbox, _ctx.showSubscription]
        ]),
        _cache[26] || (_cache[26] = _createElementVNode("span", null, "Show only no subscription", -1))
      ]),
      _createElementVNode("label", _hoisted_9, [
        _withDirectives(_createElementVNode("input", {
          id: "subscription",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showSubscription) = $event)),
          type: "checkbox",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.checkedShowSubscription && _ctx.checkedShowSubscription(...args)))
        }, null, 512), [
          [_vModelCheckbox, _ctx.showSubscription]
        ]),
        _cache[27] || (_cache[27] = _createElementVNode("span", null, "Show only no subscription", -1))
      ]),
      _createElementVNode("div", {
        class: "students__reset-filters",
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.resetFilters && _ctx.resetFilters(...args)))
      }, " Reset filters ")
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _cache[28] || (_cache[28] = _createTextVNode(" ID ")),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getStudents('?search=', 'id')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getStudents('?search=', '-id'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _cache[32] || (_cache[32] = _createElementVNode("div", { class: "main-table__head-little-tr" }, " Picture ", -1)),
        _createElementVNode("div", _hoisted_16, [
          _cache[29] || (_cache[29] = _createTextVNode(" Full Name ")),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (this.getStudents('?search=', 'full_name')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (this.getStudents('?search=', '-full_name'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[30] || (_cache[30] = _createTextVNode(" Email ")),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (this.getStudents('?search=', 'email')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[15] || (_cache[15] = ($event: any) => (this.getStudents('?search=', '-email'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _cache[31] || (_cache[31] = _createTextVNode(" Phone Number ")),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[16] || (_cache[16] = ($event: any) => (this.getStudents('?search=', 'phone')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[17] || (_cache[17] = ($event: any) => (this.getStudents('?search=', '-phone'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " Sport ", -1)),
        _cache[34] || (_cache[34] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " School ", -1))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentsData, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "main-table__body",
          key: item
        }, [
          _createElementVNode("div", _hoisted_22, _toDisplayString(item.id), 1),
          _createElementVNode("div", _hoisted_23, [
            (item.image === null)
              ? (_openBlock(), _createBlock(_component_Picture, { key: 0 }))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: item.image,
                  alt: "item.full_name"
                }, null, 8, _hoisted_24))
          ]),
          _createElementVNode("div", _hoisted_25, _toDisplayString(item.full_name), 1),
          _createElementVNode("div", _hoisted_26, _toDisplayString(item.email), 1),
          _createElementVNode("div", _hoisted_27, _toDisplayString(item?.phone?.value), 1),
          _createElementVNode("div", _hoisted_28, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.sports, (sport) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sport.id
              }, _toDisplayString(sport?.name?.title), 1))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_29, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.schools, (school) => {
              return (_openBlock(), _createElementBlock("div", {
                key: school.id
              }, _toDisplayString(school?.title), 1))
            }), 128))
          ]),
          _createVNode(_component_AddInstructor, {
            onClick: ($event: any) => (_ctx.openPopupChoosenInstructor(item.id, item.instructors)),
            class: "main-table__svg students__svg"
          }, null, 8, ["onClick"]),
          _createVNode(_component_View, {
            class: "main-table__svg",
            onClick: ($event: any) => (_ctx.viewStudent(item.id))
          }, null, 8, ["onClick"]),
          _createVNode(_component_Edit, {
            class: "main-table__svg",
            onClick: ($event: any) => (_ctx.editstudent(item.id))
          }, null, 8, ["onClick"]),
          _createVNode(_component_Delete, {
            class: "main-table__svg",
            onClick: ($event: any) => (_ctx.openDeletePopup(item.id))
          }, null, 8, ["onClick"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_Pagination, {
      propertyMeta: _ctx.studentsMeta,
      propertyList: _ctx.studentsData,
      page: _ctx.page,
      handlePage: _ctx.handlePage
    }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "students__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.deleteStudent && _ctx.deleteStudent(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "students__popup-button-no",
                onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalChooseInstructor)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 1,
          "on-close": _ctx.handleCloseModalChooseInstructor,
          class: "students__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_31, [
              _cache[36] || (_cache[36] = _createElementVNode("div", { class: "students__popup-title" }, " Choose instructor for student ", -1)),
              _createElementVNode("div", _hoisted_32, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: "Search",
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.searchDataInstructors) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.searchDataInstructors]
                ]),
                _createVNode(_component_Search)
              ]),
              _createElementVNode("div", _hoisted_33, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorsData, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, [
                    _createElementVNode("label", {
                      for: item.id,
                      class: "custom-checkbox"
                    }, [
                      (_ctx.isCheckedInstructor(item.id))
                        ? (_openBlock(), _createElementBlock("input", {
                            key: 0,
                            id: item.id,
                            name: item.full_name,
                            onClick: ($event: any) => (_ctx.unAttachInstructors(item.id)),
                            checked: _ctx.isCheckedInstructor(item.id),
                            type: "checkbox"
                          }, null, 8, _hoisted_35))
                        : (_openBlock(), _createElementBlock("input", {
                            key: 1,
                            id: item.id,
                            name: item.full_name,
                            onClick: ($event: any) => (_ctx.attachInstructors(item.id)),
                            checked: _ctx.isCheckedInstructor(item.id),
                            type: "checkbox"
                          }, null, 8, _hoisted_36)),
                      _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                    ], 8, _hoisted_34),
                    _createElementVNode("div", _hoisted_37, _toDisplayString(this.errorAttachInstructor), 1)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalChooseInstructorNewStudent)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 2,
          "on-close": _ctx.handleCloseModalChooseInstructorNewStudent,
          class: "students__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_38, [
              _cache[37] || (_cache[37] = _createElementVNode("div", { class: "students__popup-title" }, " Choose instructor for student ", -1)),
              _createElementVNode("div", _hoisted_39, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: "Search",
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.searchDataInstructors) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.searchDataInstructors]
                ]),
                _createVNode(_component_Search)
              ]),
              _createElementVNode("div", _hoisted_40, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorsData, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, [
                    _createElementVNode("label", {
                      for: item.id,
                      class: "custom-checkbox"
                    }, [
                      _createElementVNode("input", {
                        id: item.id,
                        name: item.full_name,
                        onClick: ($event: any) => (_ctx.selectInstructors(item.id)),
                        checked: _ctx.isChecked(item.id),
                        type: "checkbox"
                      }, null, 8, _hoisted_42),
                      _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                    ], 8, _hoisted_41)
                  ]))
                }), 128)),
                _createElementVNode("div", {
                  class: "students__button-generate-code",
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (this.generateInviteCodeStudent()))
                }, " Generate Code ")
              ])
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalCopyCode)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 3,
          "on-close": _ctx.handleCloseModalCopyCode,
          class: "students__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_43, [
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "students__popup-title-copy-code" }, " Code for student generated ", -1)),
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, _toDisplayString(this.generateStateCode?.code), 1),
                _createElementVNode("div", {
                  class: "students__copy-code-button",
                  onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.copyText()))
                }, " Copy ")
              ])
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_46, " Code copied! "))
      : _createCommentVNode("", true)
  ]))
}